import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const JeffersonHealthLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. David Altman"
    institution="Jefferson Health"
    referralCode="ALTMAN"
    physicianURL="https://www.abingtonhealth.org/find-a-doctor/profile/doctor-david-altman/"
  />
)

export default JeffersonHealthLandingPage